<template>
  <main class="col-md-12 ms-sm-auto col-lg-12 px-md-4">
    <div class="container mt-5">
      <div class="row row-cols-1 row-cols-md-3 mb-3 text-center justify-content-center">               
        <div class="col-12">
          <img src="@/assets/img/up-01.svg" width="400" alt="">
          <div align="center">
            <h1 class="h1-404 mt-4">Hey Upgrade your plan!</h1>            
            <h2 class="mt-4">
              Please upgrade your plan to access this functionality
            </h2>
            <div class="col-md-3 mt-4">
              <a class=" button-1 w-100 btn btn-xs btn-primary"  @click="$router.go(-1)"> Go Back</a>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default
  {
    name: 'Upgrade' 
  }
</script>

<style></style>
